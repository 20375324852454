import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import UserService from "../services/user.service";

import TmdService from "../services/tmd.service";

import "../components/Home.css";


import moment from 'moment'
import ReactGA from "react-ga4";


import "leaflet/dist/leaflet.css";
import L from "leaflet";








import { Button, Badge, Container, Card, CardGroup, ListGroup, Col, Row, Nav, Navbar, NavDropdown, Form, Image } from 'react-bootstrap';

import Carousel from 'react-bootstrap/Carousel';

import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official'
import HighchartsReact from 'highcharts-react-official';
//import * as Highcharts from 'highcharts';
// require('highcharts/modules/exporting')(Highcharts);
// require('highcharts/modules/export-data')(Highcharts);
//import addTreemapModule from 'highcharts/modules/treemap';
//import Highcharts from 'highcharts';
import * as Highcharts from 'highcharts/highstock';
//addTreemapModule(Highcharts);
// import HighchartsData from "highcharts/modules/data";
// import HighchartsExporting from "highcharts/modules/exporting";
// import HighchartsHeatmap from "highcharts/modules/heatmap";
//import HighchartsTreeChart from "highcharts/modules/treemap";
//import Dashboards from '@highcharts/dashboards';
//import HighchartsPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/HighchartsPlugin';
import { DownloadDone, WidthFull } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material';
import LayersIcon from '@mui/icons-material/Layers';
import Tooltip from '@mui/material/Tooltip';

import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'

import {
  Box,
  Stack,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Skeleton,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Fab,
  Snackbar,
  colors,
  // getIconButtonUtilityClass
} from "@mui/material";




let sphere = null;
let map = null;
let displayKey = null;
let lat = 13.10;
let lon = 100.00;

// let currentDate = new Date();
// let year = new Date(currentDate.getFullYear(), 0, 1);
// let days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
// let week = Math.ceil((currentDate.getDay() + 1 + days) / 7);
// console.log(week)

Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof (dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
  var newYear = new Date
  //(this.getFullYear(), 0, 1);
  console.log(newYear)
  var newYear_n = new Date(newYear.getFullYear(), 0, 1)
  console.log(newYear_n);
  var day = newYear_n.getDay() - dowOffset; //the day of week the year begins on
  day = (day >= 0 ? day : day + 7);
  var daynum = Math.floor((this.getTime() - newYear_n.getTime() -
    (this.getTimezoneOffset() - newYear_n.getTimezoneOffset()) * 60000) / 86400000) + 1;
  console.log(daynum)
  var weeknum;
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    console.log(weeknum)
    if (weeknum > 52) {
      newYear_n = new Date(this.getFullYear() + 1, 0, 1);
      console.log(nYear)
      nday = nYear.getDay() - dowOffset;
      console.log(nday)
      nday = nday >= 0 ? nday : nday + 7;

      /*if the next year starts before the middle of
        the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53;
    }
  }
  else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }
  return weeknum;

};


Date.prototype.getWeek = function() {
  var onejan = new Date(this.getFullYear(), 0, 1);
  return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
}
var weekNumber = (new Date()).getWeek()-1;
console.log(weekNumber)

var dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
var now = new Date();
console.log(dayNames[now.getDay()] + " (" + weekNumber + ").");

let mydate = new Date();
mydate.setDate(mydate.getDate() - 7);
console.log(mydate);


//alert(mydate.getWeek() - 1);
let week = (mydate.getWeek());
console.log(week)

let first = mydate.getDate() - mydate.getDay(); // First day is the day of the month - the day of the week
let last = first + 6; // last day is the first day + 6


// let firstday = new Date(mydate.setDate(first)).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
// console.log(firstday)
// let lastday = new Date(mydate.setDate(last)).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
// console.log(lastday)


let date_today = new Date();
console.log(`The current day index of the week is: ${date_today.getDay()+1 }`);

console.log(`The current date of the week is: ${date_today.getDate() - 7}`);

let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate() 
  - date_today.getDay()-6)) ;

let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
  - date_today.getDay()+7));
console.log(`The first date of the week is: ${first_day_of_the_week}`);
console.log(`The last day of the week is: ${last_day_of_the_week}`);

var firstday = first_day_of_the_week.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
console.log(firstday)
var lastday = last_day_of_the_week.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
console.log(lastday)


//จัดformatวันที่
var dateString = new Date;
console.log(dateString)
var m = moment(dateString, 'ddd MMM D YYYY HH:mm:ss ZZ');
// Use moment(Date) if your input is a JS Date
//var m = moment(date);
m.set({ m: 0, s: 0, });
//console.log(m.format());
//console.log(m.toDate().toString());

var data_time = m.format().replace(/T.*/, 'T00:00:00Z')
var data_time_ = data_time.replace('T00:00:00Z', '')

var ddd = m.format()
//console.log('ddd', ddd);


var dddd = (new Date()).toLocaleDateString('th-TH', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
//console.log('dddd', dddd);
//แปลงเวลา
var d = new Date(ddd);
//console.log('d',d); // If you are not in UTC then this will show the Date with timezone adjustment applied
var hourAndMinute = { hour: "numeric", minute: "numeric" };
var formatter = new Intl.DateTimeFormat("en-GB", hourAndMinute);
var result_date = formatter.format(d); // This does the reverse timezone adjustment
//console.log(result_date);


const provinces = [
  { "label": "ประเทศไทย", "pv_idn": null },
  { "label": "กำแพงเพชร", "pv_idn": 62 },
  { "label": "จันทบุรี", "pv_idn": 22 },
  { "label": "อุบลราชธานี", "pv_idn": 34 },
  { "label": "นครสวรรค์", "pv_idn": 60 },
  { "label": "กรุงเทพมหานคร", "pv_idn": 10 },
  { "label": "สมุทรสาคร", "pv_idn": 74 },
  { "label": "ชุมพร", "pv_idn": 86 },
  { "label": "นครปฐม", "pv_idn": 73 },
  { "label": "เชียงใหม่", "pv_idn": 50 },
  { "label": "ปัตตานี", "pv_idn": 94 },
  { "label": "ชัยภูมิ", "pv_idn": 36 },
  { "label": "พังงา", "pv_idn": 82 },
  { "label": "แพร่", "pv_idn": 54 },
  { "label": "ปทุมธานี", "pv_idn": 13 },
  { "label": "นนทบุรี", "pv_idn": 12 },
  { "label": "มุกดาหาร", "pv_idn": 49 },
  { "label": "พระนครศรีอยุธยา", "pv_idn": 14 },
  { "label": "ยะลา", "pv_idn": 95 },
  { "label": "กระบี่", "pv_idn": 81 },
  { "label": "ร้อยเอ็ด", "pv_idn": 45 },
  { "label": "ศรีสะเกษ", "pv_idn": 33 },
  { "label": "ตาก", "pv_idn": 63 },
  { "label": "บุรีรัมย์", "pv_idn": 31 },
  { "label": "อุดรธานี", "pv_idn": 41 },
  { "label": "หนองบัวลำภู", "pv_idn": 39 },
  { "label": "สกลนคร", "pv_idn": 47 },
  { "label": "ภูเก็ต", "pv_idn": 83 },
  { "label": "อุทัยธานี", "pv_idn": 61 },
  { "label": "หนองคาย", "pv_idn": 43 },
  { "label": "ลำปาง", "pv_idn": 52 },
  { "label": "ตรัง", "pv_idn": 92 },
  { "label": "นครศรีธรรมราช", "pv_idn": 80 },
  { "label": "กาฬสินธุ์", "pv_idn": 46 },
  { "label": "อำนาจเจริญ", "pv_idn": 37 },
  { "label": "อุตรดิตถ์", "pv_idn": 53 },
  { "label": "ฉะเชิงเทรา", "pv_idn": 24 },
  { "label": "มหาสารคาม", "pv_idn": 44 },
  { "label": "สมุทรปราการ", "pv_idn": 11 },
  { "label": "กาญจนบุรี", "pv_idn": 71 },
  { "label": "น่าน", "pv_idn": 55 },
  { "label": "นครพนม", "pv_idn": 48 },
  { "label": "เลย", "pv_idn": 42 },
  { "label": "ลพบุรี", "pv_idn": 16 },
  { "label": "สมุทรสงคราม", "pv_idn": 75 },
  { "label": "ระยอง", "pv_idn": 21 },
  { "label": "ประจวบคีรีขันธ์", "pv_idn": 77 },
  { "label": "สุราษฎร์ธานี", "pv_idn": 84 },
  { "label": "เพชรบุรี", "pv_idn": 76 },
  { "label": "ลำพูน", "pv_idn": 51 },
  { "label": "นครนายก", "pv_idn": 26 },
  { "label": "สุพรรณบุรี", "pv_idn": 72 },
  { "label": "ยโสธร", "pv_idn": 35 },
  { "label": "ขอนแก่น", "pv_idn": 40 },
  { "label": "สระแก้ว", "pv_idn": 27 },
  { "label": "ชัยนาท", "pv_idn": 18 },
  { "label": "นราธิวาส", "pv_idn": 96 },
  { "label": "สระบุรี", "pv_idn": 19 },
  { "label": "สตูล", "pv_idn": 91 },
  { "label": "พะเยา", "pv_idn": 56 },
  { "label": "ราชบุรี", "pv_idn": 70 },
  { "label": "พิษณุโลก", "pv_idn": 65 },
  { "label": "เชียงราย", "pv_idn": 57 },
  { "label": "สงขลา", "pv_idn": 90 },
  { "label": "ชลบุรี", "pv_idn": 20 },
  { "label": "แม่ฮ่องสอน", "pv_idn": 58 },
  { "label": "พัทลุง", "pv_idn": 93 },
  { "label": "อ่างทอง", "pv_idn": 15 },
  { "label": "ตราด", "pv_idn": 23 },
  { "label": "สิงห์บุรี", "pv_idn": 17 },
  { "label": "บึงกาฬ", "pv_idn": 38 },
  { "label": "นครราชสีมา", "pv_idn": 30 },
  { "label": "เพชรบูรณ์", "pv_idn": 67 },
  { "label": "สุโขทัย", "pv_idn": 64 },
  { "label": "ปราจีนบุรี", "pv_idn": 25 },
  { "label": "ระนอง", "pv_idn": 85 },
  { "label": "พิจิตร", "pv_idn": 66 },
  { "label": "สุรินทร์", "pv_idn": 32 }
];

//console.log(provinces[0].label)

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey'
  }
];


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Home = () => {

  const [openDialog, setOpenDialog] = useState(true)
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const mapContainerRef = useRef(null);
  const map = useRef(null);

  const [lng] = useState(100.7431);
  const [lat] = useState(13.2672);
  const [zoom] = useState(6);

  const [content, setContent] = useState("");
  const [provinceValue, setProvinceValue] = useState(provinces[0]);
  const [provinceValue_, setProvinceValue_] = useState(provinces[0].label);
  const [isLoading, setIsLoading] = useState(false);

  //const [mapData, setmapData] = useState([]);

  const [isJwt, setisJwt] = useState("");


  //graph_hour
  const [data_temp_h, setdata_temp_h] = useState("");
  const [dataname_h, setdataname_h] = useState("");
  const [datanamepro_h, setdatanamepro_h] = useState("");
  const [data_rain_h, setdata_rain_h] = useState("");
  const [data_weatherTypeText_h, setdata_weatherTypeText_h] = useState("");
  const [data_windSpeed_h, setdata_windSpeed_h] = useState("");
  const [data_rh_h, setdata_rh_h] = useState("");

  //graph_daily
  const [data_temp_min, setdata_temp_min] = useState("");
  const [data_temp_max, setdata_temp_max] = useState("");
  const [dataname, setdataname] = useState("");
  const [datanamepro, setdatanamepro] = useState("");
  const [data_rain, setdata_rain] = useState("");
  const [data_weatherTypeText, setdata_weatherTypeText] = useState("");
  const [data_windSpeed, setdata_windSpeed] = useState("");
  const [data_rh, setdata_rh] = useState("");
  const [data_weatherTypeImagePath, setdata_weatherTypeImagePath] = useState("");

  //graph_count_province
  const [data_pv_tn_count, setdata_pv_tn_count] = useState("");
  const [data_mean_count_province, setdata_mean_count_province] = useState("");
  const [data_des_count_province, setdata_des_count_province] = useState("");
  const [data_no_order_count_province, setdata_no_order_count_province] = useState("");
  const [data_pv_code_count_province, setdata_pv_code_count_province] = useState("");
  const [data_geometry_count_province, setdata_geometry_count_province] = useState("");

  //graph_count_province_sum
  const [data_pv_tn_count1_1, setdata_pv_tn_count1] = useState("");
  const [data_pv_tn_count2_2, setdata_pv_tn_count2] = useState("");
  const [data_pv_tn_count3_3, setdata_pv_tn_count3] = useState("");
  const [data_pv_tn_count4_4, setdata_pv_tn_count4] = useState("");
  const [data_pv_tn_count5_5, setdata_pv_tn_count5] = useState("");

  //jwt
  // const getJwt = async (url) => {
  //   const response = await fetch(url, { method: 'POST' });
  //   const result = await response.json();
  //   //console.log(result.token)
  //   setisJwt(result.token)
  // };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };



  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  //test
  const gettest = async () => {
    //setIsLoadingChart(true);
    //const headers = { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM3Y2U1NjgwZGVlMWVjOTBkOWJmYTY4MDIxZTlmNTY2ZmNkMWZlNDg4OTRiOGM3MmE3OTg1ZDYzZGNhNGM5OGZlNWVlMjI3ZjkwYmYxNjZlIn0.eyJhdWQiOiIyIiwianRpIjoiYzdjZTU2ODBkZWUxZWM5MGQ5YmZhNjgwMjFlOWY1NjZmY2QxZmU0ODg5NGI4YzcyYTc5ODVkNjNkY2E0Yzk4ZmU1ZWUyMjdmOTBiZjE2NmUiLCJpYXQiOjE2OTYyMjE3MDcsIm5iZiI6MTY5NjIyMTcwNywiZXhwIjoxNzI3ODQ0MTA3LCJzdWIiOiIyNzE5Iiwic2NvcGVzIjpbXX0.qWTYKdEEWtTo1SlCwnbEzeKXv0o7yJ04ObFr7ioY3XQcLpLxGBGariMonvypf2c_vpJLz0eJVEDPBt5maGvWpldrUoARAZ3tgiAEACGim0CiU_Sdx9mfL1FagXY0czRFUZyrSc7D-2mJaeF59w-lRVLUTNnw51TserF7rUoyqAg9EJGc4GaMQ0_JmSmUlXjTwvH59mtIxsXuuB-6ou36RA2MoepvV2Q5KOQ_c0HxgIBM97YvrlNY1GIbG3TlFUSIdwtn-MvTrVth6SdKSlZyTJqePT9pHG8CWUY2ToLQFJfZDiCjkE3Tlg3gjJ-S5GGGVTpuiY49Q3LFDst0y8U03UeqefSBVXbGdyYsItIrkAeDdO24re51Yn0OUNtcEd4HY8n9RJH9QyT4Dzk-EW0NVnhafbbvvUigRUZc6WBIVNq2oxA5hDw0WFmjLRseYMKrifmSC3LhsNPwT9LPvBUYaClaEkOSdgV2u07qS15QpfKk0wgTWFTIf4lcAs4hy7nb0CjN0xMCscBhN7MB8_ghdYw7Z6xCrSnxJM4rFcjZJHdB9zWqQ4mNSbKVAzJ_9g7Vt4Lc29AqCcI5EZd-saQtSEKZwmo-YrTj7Xp4RiVlcyxDqmRfeTRmNU5_D-OD3KhUy2lhR8uz1UWur84not-DsMGhBkJhimaWcdzgnuWXss4' };
    const response = await axios.get(
      `https://cropsdrought-service.gistda.or.th/api/carbon/getCropsAll`
      ,
      //{ headers }
    );
    console.log(response.data);

    // const result = response.data
    // //setIsLoadingChart(false);

    // //const groupedData = {};

    // // console.log(result.WeatherForecasts[0].forecasts);
    // // console.log(result.WeatherForecasts[0].location.province);


    // //const data_g = []

    // var data_temp_h = [];
    // var dataname_h = [];
    // var datanamepro_h = [];
    // var data_rain_h = [];
    // var data_weatherTypeText_h = [];
    // var data_windSpeed_h = [];
    // var data_rh_h = [];

    // for (var i = 0; i < result.WeatherForecasts[0].forecasts.length; i++) {
    //   data_temp_h.push(result.WeatherForecasts[0].forecasts[i].data.tc);
    //   data_rain_h.push(result.WeatherForecasts[0].forecasts[i].data.rain);
    //   data_windSpeed_h.push(result.WeatherForecasts[0].forecasts[i].data.ws10m);
    //   data_rh_h.push(result.WeatherForecasts[0].forecasts[i].data.rh);
    //   data_weatherTypeText_h.push(result.WeatherForecasts[0].forecasts[i].data.cond);
    //   datanamepro_h.push(result.WeatherForecasts[0].location.province);
    //   dataname_h.push(result.WeatherForecasts[0].forecasts[i].time.split('T')[1].replace(':00+07:00', '') + ' น.');

    // }

    // // console.log(data_temp_h)
    // // console.log(dataname_h)
    // // console.log(data_weatherTypeText_h)
    // // console.log(datanamepro_h)

    // setdata_temp_h(data_temp_h)
    // setdata_rain_h(data_rain_h)
    // setdata_weatherTypeText_h(data_weatherTypeText_h)
    // setdata_windSpeed_h(data_windSpeed_h)
    // setdataname_h(dataname_h)
    // setdatanamepro_h(datanamepro_h)
    // setdata_windSpeed_h(data_windSpeed_h)
    // setdata_rh_h(data_rh_h)

    // setIsLoading(false);
    //console.log(isLoading)
  }


  //รายชั่วโมง
  const getChartData_h = async (provinceValue) => {
    //setIsLoadingChart(true);
    const headers = { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM3Y2U1NjgwZGVlMWVjOTBkOWJmYTY4MDIxZTlmNTY2ZmNkMWZlNDg4OTRiOGM3MmE3OTg1ZDYzZGNhNGM5OGZlNWVlMjI3ZjkwYmYxNjZlIn0.eyJhdWQiOiIyIiwianRpIjoiYzdjZTU2ODBkZWUxZWM5MGQ5YmZhNjgwMjFlOWY1NjZmY2QxZmU0ODg5NGI4YzcyYTc5ODVkNjNkY2E0Yzk4ZmU1ZWUyMjdmOTBiZjE2NmUiLCJpYXQiOjE2OTYyMjE3MDcsIm5iZiI6MTY5NjIyMTcwNywiZXhwIjoxNzI3ODQ0MTA3LCJzdWIiOiIyNzE5Iiwic2NvcGVzIjpbXX0.qWTYKdEEWtTo1SlCwnbEzeKXv0o7yJ04ObFr7ioY3XQcLpLxGBGariMonvypf2c_vpJLz0eJVEDPBt5maGvWpldrUoARAZ3tgiAEACGim0CiU_Sdx9mfL1FagXY0czRFUZyrSc7D-2mJaeF59w-lRVLUTNnw51TserF7rUoyqAg9EJGc4GaMQ0_JmSmUlXjTwvH59mtIxsXuuB-6ou36RA2MoepvV2Q5KOQ_c0HxgIBM97YvrlNY1GIbG3TlFUSIdwtn-MvTrVth6SdKSlZyTJqePT9pHG8CWUY2ToLQFJfZDiCjkE3Tlg3gjJ-S5GGGVTpuiY49Q3LFDst0y8U03UeqefSBVXbGdyYsItIrkAeDdO24re51Yn0OUNtcEd4HY8n9RJH9QyT4Dzk-EW0NVnhafbbvvUigRUZc6WBIVNq2oxA5hDw0WFmjLRseYMKrifmSC3LhsNPwT9LPvBUYaClaEkOSdgV2u07qS15QpfKk0wgTWFTIf4lcAs4hy7nb0CjN0xMCscBhN7MB8_ghdYw7Z6xCrSnxJM4rFcjZJHdB9zWqQ4mNSbKVAzJ_9g7Vt4Lc29AqCcI5EZd-saQtSEKZwmo-YrTj7Xp4RiVlcyxDqmRfeTRmNU5_D-OD3KhUy2lhR8uz1UWur84not-DsMGhBkJhimaWcdzgnuWXss4' };
    const response = await axios.get(
      `https://data.tmd.go.th/nwpapi/v1/forecast/location/hourly/place?&province=${provinceValue}&fields=tc,rh,rain,ws10m,cond&date=${data_time_}&&duration=24`
      , { headers });
    //console.log(response.data);

    const result = response.data
    //setIsLoadingChart(false);

    //const groupedData = {};

    // console.log(result.WeatherForecasts[0].forecasts);
    // console.log(result.WeatherForecasts[0].location.province);


    //const data_g = []

    var data_temp_h = [];
    var dataname_h = [];
    var datanamepro_h = [];
    var data_rain_h = [];
    var data_weatherTypeText_h = [];
    var data_windSpeed_h = [];
    var data_rh_h = [];

    for (var i = 0; i < result.WeatherForecasts[0].forecasts.length; i++) {
      data_temp_h.push(result.WeatherForecasts[0].forecasts[i].data.tc);
      data_rain_h.push(result.WeatherForecasts[0].forecasts[i].data.rain);
      data_windSpeed_h.push(result.WeatherForecasts[0].forecasts[i].data.ws10m);
      data_rh_h.push(result.WeatherForecasts[0].forecasts[i].data.rh);
      data_weatherTypeText_h.push(result.WeatherForecasts[0].forecasts[i].data.cond);
      datanamepro_h.push(result.WeatherForecasts[0].location.province);
      dataname_h.push(result.WeatherForecasts[0].forecasts[i].time.split('T')[1].replace(':00+07:00', '') + ' น.');

    }

    // console.log(data_temp_h)
    // console.log(dataname_h)
    // console.log(data_weatherTypeText_h)
    // console.log(datanamepro_h)

    setdata_temp_h(data_temp_h)
    setdata_rain_h(data_rain_h)
    setdata_weatherTypeText_h(data_weatherTypeText_h)
    setdata_windSpeed_h(data_windSpeed_h)
    setdataname_h(dataname_h)
    setdatanamepro_h(datanamepro_h)
    setdata_windSpeed_h(data_windSpeed_h)
    setdata_rh_h(data_rh_h)

    setIsLoading(false);
    //console.log(isLoading)
  }

  //รายวัน
  const getChartData = async (provinceValue) => {
    //setIsLoadingChart(true);

    const headers = { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM3Y2U1NjgwZGVlMWVjOTBkOWJmYTY4MDIxZTlmNTY2ZmNkMWZlNDg4OTRiOGM3MmE3OTg1ZDYzZGNhNGM5OGZlNWVlMjI3ZjkwYmYxNjZlIn0.eyJhdWQiOiIyIiwianRpIjoiYzdjZTU2ODBkZWUxZWM5MGQ5YmZhNjgwMjFlOWY1NjZmY2QxZmU0ODg5NGI4YzcyYTc5ODVkNjNkY2E0Yzk4ZmU1ZWUyMjdmOTBiZjE2NmUiLCJpYXQiOjE2OTYyMjE3MDcsIm5iZiI6MTY5NjIyMTcwNywiZXhwIjoxNzI3ODQ0MTA3LCJzdWIiOiIyNzE5Iiwic2NvcGVzIjpbXX0.qWTYKdEEWtTo1SlCwnbEzeKXv0o7yJ04ObFr7ioY3XQcLpLxGBGariMonvypf2c_vpJLz0eJVEDPBt5maGvWpldrUoARAZ3tgiAEACGim0CiU_Sdx9mfL1FagXY0czRFUZyrSc7D-2mJaeF59w-lRVLUTNnw51TserF7rUoyqAg9EJGc4GaMQ0_JmSmUlXjTwvH59mtIxsXuuB-6ou36RA2MoepvV2Q5KOQ_c0HxgIBM97YvrlNY1GIbG3TlFUSIdwtn-MvTrVth6SdKSlZyTJqePT9pHG8CWUY2ToLQFJfZDiCjkE3Tlg3gjJ-S5GGGVTpuiY49Q3LFDst0y8U03UeqefSBVXbGdyYsItIrkAeDdO24re51Yn0OUNtcEd4HY8n9RJH9QyT4Dzk-EW0NVnhafbbvvUigRUZc6WBIVNq2oxA5hDw0WFmjLRseYMKrifmSC3LhsNPwT9LPvBUYaClaEkOSdgV2u07qS15QpfKk0wgTWFTIf4lcAs4hy7nb0CjN0xMCscBhN7MB8_ghdYw7Z6xCrSnxJM4rFcjZJHdB9zWqQ4mNSbKVAzJ_9g7Vt4Lc29AqCcI5EZd-saQtSEKZwmo-YrTj7Xp4RiVlcyxDqmRfeTRmNU5_D-OD3KhUy2lhR8uz1UWur84not-DsMGhBkJhimaWcdzgnuWXss4' };
    const response = await axios.get(
      `https://data.tmd.go.th/nwpapi/v1/forecast/location/daily/place?province=${provinceValue}&fields=tc_min,tc_max,rh,rain,ws10m,cond&duration=7`
      , { headers });
    //console.log(response.data);

    // var config = {
    //   method: 'get',
    //   url: 'https://data.tmd.go.th/nwpapi/v1/forecast/location/daily/place?province=อุทัยธานี&fields=tc_min,tc_max,rh,rain,ws10m,cond&duration=7',
    //   headers: {
    //     'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM3Y2U1NjgwZGVlMWVjOTBkOWJmYTY4MDIxZTlmNTY2ZmNkMWZlNDg4OTRiOGM3MmE3OTg1ZDYzZGNhNGM5OGZlNWVlMjI3ZjkwYmYxNjZlIn0.eyJhdWQiOiIyIiwianRpIjoiYzdjZTU2ODBkZWUxZWM5MGQ5YmZhNjgwMjFlOWY1NjZmY2QxZmU0ODg5NGI4YzcyYTc5ODVkNjNkY2E0Yzk4ZmU1ZWUyMjdmOTBiZjE2NmUiLCJpYXQiOjE2OTYyMjE3MDcsIm5iZiI6MTY5NjIyMTcwNywiZXhwIjoxNzI3ODQ0MTA3LCJzdWIiOiIyNzE5Iiwic2NvcGVzIjpbXX0.qWTYKdEEWtTo1SlCwnbEzeKXv0o7yJ04ObFr7ioY3XQcLpLxGBGariMonvypf2c_vpJLz0eJVEDPBt5maGvWpldrUoARAZ3tgiAEACGim0CiU_Sdx9mfL1FagXY0czRFUZyrSc7D-2mJaeF59w-lRVLUTNnw51TserF7rUoyqAg9EJGc4GaMQ0_JmSmUlXjTwvH59mtIxsXuuB-6ou36RA2MoepvV2Q5KOQ_c0HxgIBM97YvrlNY1GIbG3TlFUSIdwtn-MvTrVth6SdKSlZyTJqePT9pHG8CWUY2ToLQFJfZDiCjkE3Tlg3gjJ-S5GGGVTpuiY49Q3LFDst0y8U03UeqefSBVXbGdyYsItIrkAeDdO24re51Yn0OUNtcEd4HY8n9RJH9QyT4Dzk-EW0NVnhafbbvvUigRUZc6WBIVNq2oxA5hDw0WFmjLRseYMKrifmSC3LhsNPwT9LPvBUYaClaEkOSdgV2u07qS15QpfKk0wgTWFTIf4lcAs4hy7nb0CjN0xMCscBhN7MB8_ghdYw7Z6xCrSnxJM4rFcjZJHdB9zWqQ4mNSbKVAzJ_9g7Vt4Lc29AqCcI5EZd-saQtSEKZwmo-YrTj7Xp4RiVlcyxDqmRfeTRmNU5_D-OD3KhUy2lhR8uz1UWur84not-DsMGhBkJhimaWcdzgnuWXss4'
    //   }
    // };

    // axios  (config)
    //   .then(function (response) {
    //     console.log(JSON.stringify(response.data));
    //     console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });



    // const headers = { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM3Y2U1NjgwZGVlMWVjOTBkOWJmYTY4MDIxZTlmNTY2ZmNkMWZlNDg4OTRiOGM3MmE3OTg1ZDYzZGNhNGM5OGZlNWVlMjI3ZjkwYmYxNjZlIn0.eyJhdWQiOiIyIiwianRpIjoiYzdjZTU2ODBkZWUxZWM5MGQ5YmZhNjgwMjFlOWY1NjZmY2QxZmU0ODg5NGI4YzcyYTc5ODVkNjNkY2E0Yzk4ZmU1ZWUyMjdmOTBiZjE2NmUiLCJpYXQiOjE2OTYyMjE3MDcsIm5iZiI6MTY5NjIyMTcwNywiZXhwIjoxNzI3ODQ0MTA3LCJzdWIiOiIyNzE5Iiwic2NvcGVzIjpbXX0.qWTYKdEEWtTo1SlCwnbEzeKXv0o7yJ04ObFr7ioY3XQcLpLxGBGariMonvypf2c_vpJLz0eJVEDPBt5maGvWpldrUoARAZ3tgiAEACGim0CiU_Sdx9mfL1FagXY0czRFUZyrSc7D-2mJaeF59w-lRVLUTNnw51TserF7rUoyqAg9EJGc4GaMQ0_JmSmUlXjTwvH59mtIxsXuuB-6ou36RA2MoepvV2Q5KOQ_c0HxgIBM97YvrlNY1GIbG3TlFUSIdwtn-MvTrVth6SdKSlZyTJqePT9pHG8CWUY2ToLQFJfZDiCjkE3Tlg3gjJ-S5GGGVTpuiY49Q3LFDst0y8U03UeqefSBVXbGdyYsItIrkAeDdO24re51Yn0OUNtcEd4HY8n9RJH9QyT4Dzk-EW0NVnhafbbvvUigRUZc6WBIVNq2oxA5hDw0WFmjLRseYMKrifmSC3LhsNPwT9LPvBUYaClaEkOSdgV2u07qS15QpfKk0wgTWFTIf4lcAs4hy7nb0CjN0xMCscBhN7MB8_ghdYw7Z6xCrSnxJM4rFcjZJHdB9zWqQ4mNSbKVAzJ_9g7Vt4Lc29AqCcI5EZd-saQtSEKZwmo-YrTj7Xp4RiVlcyxDqmRfeTRmNU5_D-OD3KhUy2lhR8uz1UWur84not-DsMGhBkJhimaWcdzgnuWXss4' };
    // // const response = await fetch(
    // //   //`https://query-ecv-3n7p526kaq-as.a.run.app/get/chartData?var1=${var1}&t0=${t0.format('YYYY-MM-DD')}&t1=${t1.format('YYYY-MM-DD')}&d0=${d0}&d1=${d1}&geom=${geom}`
    // //   `https://data.tmd.go.th/nwpapi/v1/forecast/location/daily/at?lat=${lat}&lon=${lon}&fields=tc_min,tc_max,rh,rain,ws10m,cond&duration=7`
    // //   , { headers });

    // const response = await fetch(
    //   //`https://query-ecv-3n7p526kaq-as.a.run.app/get/chartData?var1=${var1}&t0=${t0.format('YYYY-MM-DD')}&t1=${t1.format('YYYY-MM-DD')}&d0=${d0}&d1=${d1}&geom=${geom}`
    //   `https://data.tmd.go.th/nwpapi/v1/forecast/location/daily/place?province=${provinceValue.label}&fields=tc_min,tc_max,rh,rain,ws10m,cond&duration=7`
    //   , { headers });

    const result = response.data
    //setIsLoadingChart(false);

    //const groupedData = {};

    // console.log(result.WeatherForecasts[0].forecasts);
    // console.log(result.WeatherForecasts[0].location.province);


    //const data_g = []

    var data_temp_min = [];
    var data_temp_max = [];
    var dataname = [];
    var datanamepro = [];
    var data_rain = [];
    var data_weatherTypeText = [];
    var data_windSpeed = [];
    var data_rh = [];
    var data_weatherTypeImagePath = [];

    for (var i = 0; i < result.WeatherForecasts[0].forecasts.length; i++) {
      data_temp_min.push(result.WeatherForecasts[0].forecasts[i].data.tc_min);
      data_temp_max.push(result.WeatherForecasts[0].forecasts[i].data.tc_max);
      data_rain.push(result.WeatherForecasts[0].forecasts[i].data.rain);
      data_windSpeed.push(result.WeatherForecasts[0].forecasts[i].data.ws10m);
      data_rh.push(result.WeatherForecasts[0].forecasts[i].data.rh);
      data_weatherTypeText.push(result.WeatherForecasts[0].forecasts[i].data.cond);
      datanamepro.push(result.WeatherForecasts[0].location.province);
      dataname.push(result.WeatherForecasts[0].forecasts[i].time.replace("T00:00:00+07:00", ""));
    }

    //console.log(data_temp_min)
    //console.log(data_temp_max)
    //console.log(dataname)
    // console.log(data_weatherTypeText)
    // console.log(datanamepro)

    setdata_temp_min(data_temp_min)
    setdata_temp_max(data_temp_max)
    setdata_rain(data_rain)
    setdata_weatherTypeText(data_weatherTypeText)
    setdata_windSpeed(data_windSpeed)
    setdataname(dataname)
    setdatanamepro(datanamepro)
    setdata_windSpeed(data_windSpeed)
    setdata_rh(data_rh)
    setdata_weatherTypeImagePath(data_weatherTypeImagePath)


    setIsLoading(false);


  }

  //สรุปรายจังหวัด
  const getChartData_count_province = async (provinceValue) => {
    //setIsLoadingChart(true);
    // const headers = { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM3Y2U1NjgwZGVlMWVjOTBkOWJmYTY4MDIxZTlmNTY2ZmNkMWZlNDg4OTRiOGM3MmE3OTg1ZDYzZGNhNGM5OGZlNWVlMjI3ZjkwYmYxNjZlIn0.eyJhdWQiOiIyIiwianRpIjoiYzdjZTU2ODBkZWUxZWM5MGQ5YmZhNjgwMjFlOWY1NjZmY2QxZmU0ODg5NGI4YzcyYTc5ODVkNjNkY2E0Yzk4ZmU1ZWUyMjdmOTBiZjE2NmUiLCJpYXQiOjE2OTYyMjE3MDcsIm5iZiI6MTY5NjIyMTcwNywiZXhwIjoxNzI3ODQ0MTA3LCJzdWIiOiIyNzE5Iiwic2NvcGVzIjpbXX0.qWTYKdEEWtTo1SlCwnbEzeKXv0o7yJ04ObFr7ioY3XQcLpLxGBGariMonvypf2c_vpJLz0eJVEDPBt5maGvWpldrUoARAZ3tgiAEACGim0CiU_Sdx9mfL1FagXY0czRFUZyrSc7D-2mJaeF59w-lRVLUTNnw51TserF7rUoyqAg9EJGc4GaMQ0_JmSmUlXjTwvH59mtIxsXuuB-6ou36RA2MoepvV2Q5KOQ_c0HxgIBM97YvrlNY1GIbG3TlFUSIdwtn-MvTrVth6SdKSlZyTJqePT9pHG8CWUY2ToLQFJfZDiCjkE3Tlg3gjJ-S5GGGVTpuiY49Q3LFDst0y8U03UeqefSBVXbGdyYsItIrkAeDdO24re51Yn0OUNtcEd4HY8n9RJH9QyT4Dzk-EW0NVnhafbbvvUigRUZc6WBIVNq2oxA5hDw0WFmjLRseYMKrifmSC3LhsNPwT9LPvBUYaClaEkOSdgV2u07qS15QpfKk0wgTWFTIf4lcAs4hy7nb0CjN0xMCscBhN7MB8_ghdYw7Z6xCrSnxJM4rFcjZJHdB9zWqQ4mNSbKVAzJ_9g7Vt4Lc29AqCcI5EZd-saQtSEKZwmo-YrTj7Xp4RiVlcyxDqmRfeTRmNU5_D-OD3KhUy2lhR8uz1UWur84not-DsMGhBkJhimaWcdzgnuWXss4' };
    const response = await axios.get(
      `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/province-search?&province=${provinceValue}`
    );
    console.log(response.data);

    const result = response.data


    //setIsLoadingChart(false);

    //const groupedData = {};

    //console.log(result.data);

    //const data_g = []

    var data_pv_tn_count = [];
    var data_mean_count_province = [];
    var data_des_count_province = [];
    var data_no_order_count_province = [];
    var data_pv_code_count_province = [];
    var data_geometry_count_province = [];


    var data_pv_tn_count_1 = []
    var data_pv_tn_count_2 = []
    var data_pv_tn_count_3 = []
    var data_pv_tn_count_4 = []
    var data_pv_tn_count_5 = []

    //var mapData = []

    for (var i = 0; i < result.data.jsonFeatures.length; i++) {
      if (result.data.jsonFeatures[i].mean_ > 0 && result.data.jsonFeatures[i].mean_ <= 50) {
        data_pv_tn_count_1.push(result.data.jsonFeatures.length);
      } else if (result.data.jsonFeatures[i].mean_ > 50.1 && result.data.jsonFeatures[i].mean_ <= 60) {
        data_pv_tn_count_2.push(result.data.jsonFeatures.length);
      } else if (result.data.jsonFeatures[i].mean_ > 60.1 && result.data.jsonFeatures[i].mean_ <= 70) {
        data_pv_tn_count_3.push(result.data.jsonFeatures.length);
      } else if (result.data.jsonFeatures[i].mean_ > 70.1 && result.data.jsonFeatures[i].mean_ <= 80) {
        data_pv_tn_count_4.push(result.data.jsonFeatures.length);
      } else if (result.data.jsonFeatures[i].mean_ > 80.1 && result.data.jsonFeatures[i].mean_ <= 100) {
        data_pv_tn_count_5.push(result.data.jsonFeatures.length);
      }
    }



    for (var i = 0; i < result.data.jsonFeatures.length; i++) {
      if (result.data.jsonFeatures[i].mean_ > 0) {
        data_pv_tn_count.push(result.data.jsonFeatures[i].pv_tn);
        data_mean_count_province.push(result.data.jsonFeatures[i].mean_);
        data_des_count_province.push(result.data.jsonFeatures[i].des_);
        data_no_order_count_province.push(result.data.jsonFeatures[i].no_order_);
        data_pv_code_count_province.push(result.data.jsonFeatures[i].pv_code);
        data_geometry_count_province.push(result.data.jsonFeatures[i].geometry);
      }
    }

    // for (var i = 0; i < result.data.jsonFeatures.length; i++) {
    //   mapData.push({
    //     // no_order_: result.data.jsonFeatures[i].no_order_,
    //     // pv_code: result.data.jsonFeatures[i].pv_code,
    //     // pv_tn: result.data.jsonFeatures[i].pv_tn,
    //     // des_: result.data.jsonFeatures[i].des_,

    //     "type": "FeatureCollection",
    //     "features": [
    //       {
    //         "type": "Feature",
    //         "properties": {
    //           no_order_: result.data.jsonFeatures[i].no_order_,
    //           pv_code: result.data.jsonFeatures[i].pv_code,
    //           pv_tn: result.data.jsonFeatures[i].pv_tn,
    //           des_: result.data.jsonFeatures[i].des_,
    //         },
    //         "geometry": result.data.jsonFeatures[i].geometry
    //       }
    //     ]


    //   })
    //   //dataname_h.push(responseTable.data.data.jsonFeatures[i].time.split('T')[1].replace(':00+07:00', '') + ' น.');

    // }

    // console.log("mapData", mapData)

    // setmapData(mapData)





    // console.log(data_pv_tn_count)
    // console.log(data_mean_count_province)
    // console.log(data_des_count_province)
    //console.log(data_no_order_count_province)

    // console.log(data_pv_tn_count_1.length)
    // console.log(data_pv_tn_count_2.length)
    // console.log(data_pv_tn_count_3.length)
    // console.log(data_pv_tn_count_4.length)
    // console.log(data_pv_tn_count_5.length)

    var data_pv_tn_count_1_1 = data_pv_tn_count_1.length
    var data_pv_tn_count_2_2 = data_pv_tn_count_2.length
    var data_pv_tn_count_3_3 = data_pv_tn_count_3.length
    var data_pv_tn_count_4_4 = data_pv_tn_count_4.length
    var data_pv_tn_count_5_5 = data_pv_tn_count_5.length




    setdata_pv_tn_count(data_pv_tn_count)
    setdata_mean_count_province(data_mean_count_province)
    setdata_des_count_province(data_des_count_province)
    setdata_no_order_count_province(data_no_order_count_province)
    setdata_pv_code_count_province(data_pv_code_count_province)
    setdata_geometry_count_province(data_geometry_count_province)


    setdata_pv_tn_count1(data_pv_tn_count_1_1)
    setdata_pv_tn_count2(data_pv_tn_count_2_2)
    setdata_pv_tn_count3(data_pv_tn_count_3_3)
    setdata_pv_tn_count4(data_pv_tn_count_4_4)
    setdata_pv_tn_count5(data_pv_tn_count_5_5)

    setIsLoading(false);
    //console.log(isLoading)
    console.log(data_mean_count_province)
  }


  const getMapData_count_province = async () => {
    //setIsLoadingChart(true);
    // const headers = { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM3Y2U1NjgwZGVlMWVjOTBkOWJmYTY4MDIxZTlmNTY2ZmNkMWZlNDg4OTRiOGM3MmE3OTg1ZDYzZGNhNGM5OGZlNWVlMjI3ZjkwYmYxNjZlIn0.eyJhdWQiOiIyIiwianRpIjoiYzdjZTU2ODBkZWUxZWM5MGQ5YmZhNjgwMjFlOWY1NjZmY2QxZmU0ODg5NGI4YzcyYTc5ODVkNjNkY2E0Yzk4ZmU1ZWUyMjdmOTBiZjE2NmUiLCJpYXQiOjE2OTYyMjE3MDcsIm5iZiI6MTY5NjIyMTcwNywiZXhwIjoxNzI3ODQ0MTA3LCJzdWIiOiIyNzE5Iiwic2NvcGVzIjpbXX0.qWTYKdEEWtTo1SlCwnbEzeKXv0o7yJ04ObFr7ioY3XQcLpLxGBGariMonvypf2c_vpJLz0eJVEDPBt5maGvWpldrUoARAZ3tgiAEACGim0CiU_Sdx9mfL1FagXY0czRFUZyrSc7D-2mJaeF59w-lRVLUTNnw51TserF7rUoyqAg9EJGc4GaMQ0_JmSmUlXjTwvH59mtIxsXuuB-6ou36RA2MoepvV2Q5KOQ_c0HxgIBM97YvrlNY1GIbG3TlFUSIdwtn-MvTrVth6SdKSlZyTJqePT9pHG8CWUY2ToLQFJfZDiCjkE3Tlg3gjJ-S5GGGVTpuiY49Q3LFDst0y8U03UeqefSBVXbGdyYsItIrkAeDdO24re51Yn0OUNtcEd4HY8n9RJH9QyT4Dzk-EW0NVnhafbbvvUigRUZc6WBIVNq2oxA5hDw0WFmjLRseYMKrifmSC3LhsNPwT9LPvBUYaClaEkOSdgV2u07qS15QpfKk0wgTWFTIf4lcAs4hy7nb0CjN0xMCscBhN7MB8_ghdYw7Z6xCrSnxJM4rFcjZJHdB9zWqQ4mNSbKVAzJ_9g7Vt4Lc29AqCcI5EZd-saQtSEKZwmo-YrTj7Xp4RiVlcyxDqmRfeTRmNU5_D-OD3KhUy2lhR8uz1UWur84not-DsMGhBkJhimaWcdzgnuWXss4' };
    const response = await axios.get(
      `https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dri%3Aprovince_with_c_dri&maxFeatures=50&outputFormat=application%2Fjson`
    );
    //console.log(response.data.features);


    var geojson_loop = [];
    for (var i = 0; i < response.data.features.length; i++) {
      geojson_loop.push(response.data.features[i]);
    }

    console.log(geojson_loop);

    var geojson = response.data.features[0]

    console.log(geojson);

    var polygon_geogonjson = new sphere.Polygon(geojson)
    map.Overlays.add(polygon_geogonjson)

  }



  //กราฟอุณหภูมิรายชั่วโมง
  const options2 = {

    chart: {
      type: 'spline'
    },
    title: {
      text: 'คาดการณ์อุณหภูมิรายชั่วโมง' + ' '
    },
    subtitle: {
      text: 'ที่มาข้อมูล: ' +
        '<a href="https://www.tmd.go.th/" ' +
        'target="_blank">กรมอุตุนิยมวิทยา</a>'
    },
    xAxis: {
      //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      categories: dataname_h
    },
    yAxis: {
      title: {
        text: 'อุณหภูมิ (°C)'
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: false
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'อุณหภูมิเฉลี่ย',
      color: '#0066FF',
      data: data_temp_h
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }


  };

  //กราฟอุณหภูมิรายวัน
  const options = {

    chart: {
      type: 'spline'
    },
    title: {
      text: 'คาดการณ์อุณหภูมิรายวัน' + ' '
    },
    subtitle: {
      text: 'ที่มาข้อมูล: ' +
        '<a href="https://www.tmd.go.th/" ' +
        'target="_blank">กรมอุตุนิยมวิทยา</a>'
    },
    xAxis: {
      //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      categories: dataname
    },
    yAxis: {
      title: {
        text: 'อุณหภูมิ (°C)'
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: false
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'อุณหภูมิตํ่าสุด',
      color: '#0066FF',
      data: data_temp_min
    }, {
      name: 'อุณหภูมิสูงสุด',
      color: '#DF603A',
      data: data_temp_max
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }


  };

  //กราฟสรุปรายจังหวัด
  // var data = data_mean_count_province;
  // var processedData = data.map(function(dataEl, index){
  // 	return {x: index, y : dataEl, color: dataEl > 50 ? 'red' : 'yellow'}
  // });
  const options3 = {

    chart: {
      type: 'bar',
      marginLeft: 150
    },
    title: {
      text: '',
      useHtml: true
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: data_pv_tn_count,
      type: 'category',
      title: {
        text: null
      },
      min: 0,
      max: 20,
      scrollbar: {
        enabled: true
      },
      //tickLength: 5
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: 'เปอร์เซ็น(%)',
        align: 'high'
      },
      stackLabels: {
        enabled: true,
        style: {
          color: "#000",
          textOutline: "none",
          fontWeight: "normal",
          fontSize: "12px",
          fontFamily: "kanit",
        },
      }
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false
        }
      },
      bar: {
        zones: [{
          value: 50.9, // Values up to 10 (not including) ...
          color: '#d5ff85', // ... have the color blue
        }, {
          value: 60.9,
          color: '#fef9d3' // Values from 10 (including) and up have the color red
        }, {
          value: 70.9,
          color: '#f9bc42' // Values from 10 (including) and up have the color red
        }, {
          value: 80.9,
          color: '#ff6600' // Values from 10 (including) and up have the color red
        }, {
          value: 100,
          color: '#cc0000' // Values from 10 (including) and up have the color red
        }
        ],
        // dataLabels: {
        //   enabled: true
        // }
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        var variance = this.series.options.data;
        //console.log(variance)
        if (variance[this.point.x] < 50) {
          return '<span class="tooltipHeader">' + this.x + '</span>' +
            '<br/> <div class="tooltipPointWrapper"' +
            '<span style="fontFamily:"' + 'Kanit' + '""class="tooltipPoint">' + 'ค่าเฉลี่ยDRI:' + '</span>' +
            '<span class="tooltipValueSuffix">' + this.y + '</span></div>'
            + '<span class="tooltipDes">ไม่ได้รับความเสี่ยง</span>'
          // return '<b>Variance: </b>' + ('<p style="color:"black">$' + Highcharts.numberFormat(variance[this.point.x], 0, ',') + '</p>');
        } else if (variance[this.point.x] > 50 && variance[this.point.x] <= 60) {
          return '<span class="tooltipHeader">' + this.x + '</span>' +
            '<br/> <div class="tooltipPointWrapper"' +
            '<span style="fontFamily:"' + 'Kanit' + '""class="tooltipPoint">' + 'ค่าเฉลี่ยDRI:' + '</span>' +
            '<span class="tooltipValueSuffix">' + this.y + '</span></div>'
            + '<span class="tooltipDes">โอกาสได้รับความเสี่ยงน้อย</span>'
          // return '<b>Variance: </b>' + ('<p style="color:"black">$' + Highcharts.numberFormat(variance[this.point.x], 0, ',') + '</p>');
        } else if (variance[this.point.x] > 60 && variance[this.point.x] <= 70) {
          return '<span class="tooltipHeader">' + this.x + '</span>' +
            '<br/> <div class="tooltipPointWrapper"' +
            '<span class="tooltipPoint">' + 'ค่าเฉลี่ยDRI:' + '</span>' +
            '<span class="tooltipValueSuffix">' + this.y + '</span></div>'
            + '<span class="tooltipDes">โอกาสได้รับความเสี่ยงปานกลาง</span>'
        } else if (variance[this.point.x] > 70 && variance[this.point.x] <= 80) {
          return '<span class="tooltipHeader">' + this.x + '</span>' +
            '<br/> <div class="tooltipPointWrapper"' +
            '<span class="tooltipPoint">ค่าเฉลี่ยDRI:</span>' +
            '<span class="tooltipValueSuffix">' + this.y + '</span></div>'
            + '<span class="tooltipDes">โอกาสได้รับความเสี่ยงสูง</span>'
        } else if (variance[this.point.x] > 80 && variance[this.point.x] <= 100) {
          return '<span class="tooltipHeader">' + this.x + '</span>' +
            '<br/> <div class="tooltipPointWrapper"' +
            '<span class="tooltipPoint">' + 'ค่าเฉลี่ยDRI:' + '</span>' +
            '<span class="tooltipValueSuffix">' + this.y + '</span></div>'
            + '<span class="tooltipDes">โอกาสได้รับความเสี่ยงสูงมาก</span>'
        }
      }

      //   // headerFormat: '<span class="tooltipHeader">{point.key}y</span>',
      //   // pointFormat: '<br/> <div class="tooltipPointWrapper">'
      //   // +
      //   // '<span class="tooltipPoint">{point.y}</span>'
      //   // +
      //   // '<span class="tooltipValueSuffix">bil KRW</span></div>'
      //   // +
      //   // '<span class="tooltipLine"></span> <br/>'
      //   // +
      //   // '<span style="color:{point.color}">\u25CF</span> {series.name}',
      //   // style: {
      //   //   color: '#fff'
      //   // },
      //   // valueDecimals: 0,
      //   // backgroundColor: '#000',
      //   // borderColor: '#000',
      //   // borderRadius: 10,
      //   // borderWidth: 3,
    },
    exporting: {
      enabled: true

    },

    series: [{
      name: 'ค่าเฉลี่ย DRI',
      data: data_mean_count_province,
      //color: '#F18C0D',
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1000
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    }

  };


  const options4 = {




  };

  Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: 'kanit'
      }
    }
  });


  var osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '© OpenStreetMap'
  });

  var osmHOT = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '© OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France'
  });

  const dri_zonal_provine = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:province_with_c_dri`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const province = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: 'dri:province	',
    format: 'image/png',
    transparent: true,
    opacity: 0.65,
    attribution: "myattribution"
  });



  const getmapData = async (url) => {
    const response = await fetch(url, { method: 'GET' });
    const result = await response.json();
    //console.log(result.data.jsonFeatures)

    var mapData = []

    for (var i = 0; i < result.data.jsonFeatures.length; i++) {
      mapData.push({
        // no_order_: result.data.jsonFeatures[i].no_order_,
        // pv_code: result.data.jsonFeatures[i].pv_code,
        // pv_tn: result.data.jsonFeatures[i].pv_tn,
        // des_: result.data.jsonFeatures[i].des_,

        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {
              no_order_: result.data.jsonFeatures[i].no_order_,
              pv_code: result.data.jsonFeatures[i].pv_code,
              pv_tn: result.data.jsonFeatures[i].pv_tn,
              des_: result.data.jsonFeatures[i].des_,
              mean_: result.data.jsonFeatures[i].mean_,
            },
            "geometry": result.data.jsonFeatures[i].geometry
          }
        ]


      })
      //dataname_h.push(responseTable.data.data.jsonFeatures[i].time.split('T')[1].replace(':00+07:00', '') + ' น.');

    }

    //console.log("mapData", mapData)

    var myStyle = {
      "opacity": 0,
      "fillOpacity": 0 // value between 0-1 or 0% - 100%
    };
    // function style (feature) {
    //   switch (feature.properties.des_) {
    //     case 'โอกาสได้รับความเสี่ยงสูงมาก': return { color: "#ff0000" };
    //     case 'โอกาสได้รับความเสี่ยงสูง': return { color: "#0000ff" };
    //   }
    // }
    function onEachFeature(feature, layer) {
      // does this feature have a property named popupContent?
      if (feature.properties && feature.properties.des_) {
        layer.bindPopup('<div class="col" style="font-size: 14px;font-weight: normal;\
        width: max-content; text-align: left; font-family:' + "'Kanit'" + ', sans-serif">\
        <label for="exampleInputPassword1">จังหวัด: '+ feature.properties.pv_tn + '</label>\
        <label for="exampleInputPassword1">ค่าเฉลี่ยดัชนีเสี่ยงภัยแล้ง(DRI): '+ feature.properties.mean_ + '</label>\
        <label for="exampleInputPassword1">ความเสี่ยง: '+ feature.properties.des_ + '</label>\
      </div>')
        layer.bindTooltip('<div class="col" style="font-size: 12px;font-weight: normal;\
        width: max-content; text-align: left; font-family:' + "'Kanit'" + ', sans-serif">\
        <label for="exampleInputPassword1">จังหวัด: '+ feature.properties.pv_tn + '</label>\
        <label for="exampleInputPassword1">ความเสี่ยง: '+ feature.properties.des_ + '</label>\
      </div>', {
          direction: "center",
          permanent: false,
          className: 'labelstyle'
        });

      }


    }



    L.geoJSON(mapData, {
      style: myStyle,
      onEachFeature: onEachFeature
    }).addTo(map.current);

    //setmapData(mapData)


    // for (let i = 0; i < result.regions.length; i++) {
    //     let region = result.regions[i];
    //     for (let j = 0; j < region.dams.length; j++) {
    //         // console.log(region.dams[j])
    //         let dam = region.dams[j];
    //         let detail = `<span style='font-weight: bold; font-size: 12px'>วันที่ : </span> <span>${dam.DMD_Date}</span><br>`;
    //         detail += `<span style='font-weight: bold; font-size: 12px'>ความจุ : </span> <span>${dam.DAM_QMax} ล้าน ลบ.ม.</span><br>`;
    //         detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำในอ่าง : </span> <span>${dam.DMD_QUse} (${dam.PERCENT_DMD_QUse}%)</span><br>`;
    //         detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำใช้การ : </span> <span>${dam.v_QUsage} ล้าน ลบ.ม.</span><br>`;
    //         detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำไหลเข้า : </span> <span>${dam.DMD_Inflow} ล้าน ลบ.ม.</span><br>`;
    //         detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำระบาย : </span> <span>${dam.DMD_Outflow} ล้าน ลบ.ม.</span><br>`;

    //         let icon = '/color-dam1.png';
    //         if (parseFloat(dam.PERCENT_DMD_QUse) > 100) { icon = '/color-dam5.png'; }
    //         else if (parseFloat(dam.PERCENT_DMD_QUse) > 81) { icon = '/color-dam4.png'; }
    //         else if (parseFloat(dam.PERCENT_DMD_QUse) > 51) { icon = '/color-dam3.png'; }
    //         else if (parseFloat(dam.PERCENT_DMD_QUse) > 31) { icon = '/color-dam2.png'; }
    //         let marker = new sphere.Marker({ lon: dam.DAM_Lon, lat: dam.DAM_Lat },
    //             {
    //                 title: dam.DAM_Name,
    //                 detail: detail,
    //                 icon: {
    //                     html: `<img width=22 height=26 src='${icon}'>`
    //                     // , offset: { x: 14, y: -12 }
    //                 },
    //             });
    //         console.log(marker)
    //         map.Overlays.add(marker);
    //     }//end for
    // }//end for

  };










  useEffect(() => {

    gettest()
    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/home", title: "home" });

    ReactGA.event('home', {
      app_name: "home",
      screen_name: "home",
    })
    //setIsLoading(true);

    //generateJwt()
    //getJwt(`https://drought-765rkyfg3q-as.a.run.app/gimmeJWT`)
    //getChartData_h()
    getChartData_h('กรุงเทพมหานคร')

    //getChartData()
    getChartData('กรุงเทพมหานคร')

    //getChartData()
    getChartData_count_province('')

    getmapData(`https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/province`);

    map.current = L.map(mapContainerRef.current,
      {
        zoomSnap: 0.25,
        zoomControl: false
      },
    )
    map.current.setView([lat, lng], 5.8);
    map.current.scrollWheelZoom.disable();

    // L.tooltip()
    // .setLatLng(latlng)
    // .setContent('Hello world!<br />This is a nice tooltip.')
    // .addTo(map.current);

    var baseMaps = {
      "OpenStreetMap": osm,
      "OpenStreetMap.HOT": osmHOT
    };
    var overlayMaps = {
      "province": province.addTo(map.current),
      "DRI": dri_zonal_provine.addTo(map.current),

    };

    var legend = L.control({ position: 'bottomright' });

    legend.onAdd = function (map) {

      var div = L.DomUtil.create('div', 'info legend'),
        grades = ["Car", "ball"],
        labels = ["http://datentaeter.de/wp-content/uploads/2016/06/flag_de.png", "http://datentaeter.de/wp-content/uploads/2016/06/flag_de.png"];

      // loop through our density intervals and generate a label with a colored square for each interval
      // for (var i = 0; i < grades.length; i++) {
      //   div.innerHTML +=
      //     grades[i] + (" <img src=" + labels[i] + " height='50' width='50'>") + '<br>';
      // }
      div.innerHTML +=
        (" <img src=" + "/legend/legend_map_dri_vertical_dri.png" + " height='140' width='120'>") + '<br>';

      return div;
    };

    legend.addTo(map.current);

    // L.control.layers(baseMaps, overlayMaps, {
    //   collapsed: true,
    // }).addTo(map.current);

    // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    //   attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
    // }).addTo(map.current);

    L.tileLayer("    https://basemap.sphere.gistda.or.th/tiles/sphere_streets/EPSG3857/{z}/{x}/{y}.png?key=test2022", {
      attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
    }).addTo(map.current);

    // L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}", {
    //   attribution: 'Sample <b>worldwide locked demo</b> by <a href="https://theroamingworkshop.cloud">The Roaming Workshop</a>. Uses ESRI tiles.',
    // }).addTo(map.current); //custom attribution text with hyperlink

    // L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    //   attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    // });

    L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
      layers: `dri:province_with_c_dri`,
      format: 'image/png',
      transparent: true,
      attribution: "myattribution"
    }).addTo(map.current);

    L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
      layers: 'dri:province	',
      format: 'image/png',
      transparent: true,
      opacity: 0.65,
      attribution: "myattribution"
    }).addTo(map.current);








    //console.log(mapData)

    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },

      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );





    //getMapData_count_province('')





    //sphere
    // sphere = window.sphere
    // map = new sphere.Map({ placeholder: document.getElementById('map'), });
    // map._this = this;

    // map.Event.bind("ready", function () {
    //   map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
    //   map.Ui.DPad.visible(false);
    //   // map.Ui.Geolocation.visible(false);
    //   map.Ui.LayerSelector.visible(false);
    //   let layer_dri_province = new sphere.Layer('dri:province_with_c_dri', {
    //     type: sphere.LayerType.WMS,
    //     url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
    //     zoomRange: { min: 1, max: 6.5 },
    //     zIndex: 5,
    //     opacity: 0.65,
    //     id: 'layer_dri_province'
    //   });
    //   map.Layers.add(layer_dri_province);
    //   // let layer_test = new sphere.Layer('sense:test', {
    //   //   type: sphere.LayerType.WMS,
    //   //   url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/sense/wms?",
    //   //   zoomRange: { min: 1, max: 21 },
    //   //   zIndex: 5,
    //   //   opacity: 0.65,
    //   //   id: 'test'
    //   // });
    //   // map.Layers.add(layer_test);
    //   //getMapData_count_province()



    //   //addWFSLayer();




    //   // let layer_dri_amphoe = new sphere.Layer('dri:amphoe_with_c_dri', {
    //   //   type: sphere.LayerType.WMS,
    //   //   url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
    //   //   zoomRange: { min: 6.51, max: 8.9 },
    //   //   zIndex: 6,
    //   //   opacity: 0.65,
    //   //   id: 'layer_dri_amphoe'
    //   // });
    //   // map.Layers.add(layer_dri_amphoe);
    //   // let layer_dri_tambon = new sphere.Layer('dri:tambon_with_c_dri', {
    //   //   type: sphere.LayerType.WMS,
    //   //   url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
    //   //   zoomRange: { min: 8.91, max: 18 },
    //   //   zIndex: 7,
    //   //   opacity: 0.65,
    //   //   id: 'layer_dri_tambon'
    //   // });
    //   // map.Layers.add(layer_dri_tambon);
    // });



    // map.Event.bind(sphere.EventName.Click, function (location) {
    //   // console.log(location);
    //   // getDataLocation(location.lon, location.lat);
    //   //map.mouseLocation = location;
    //   var location = map.location(); // Cross hair location
    //   console.log(location);
    // });






    // const interval = setInterval(() => {
    //   // setDateTime(new Date());
    //   //reloadData();
    //   goHome();
    // }, 300000); //set your time here. repeat every 5 mins

    // return () => {
    //   //window.removeEventListener('resize', handleWindowResize);
    //   clearInterval(interval);
    // };

    return () => {
      map.current.remove();

    };

  }, [lat, lng, zoom]);





  const goHome = async () => {
    map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
  }

  const onProvinceChange = (e, v) => {
    setProvinceValue(v);
    console.log(v.label)
    getChartData(v.label)
    getChartData_h(v.label)

    //drillThailand(v.label);
  }

  // const onProvinceChange = (e) => {
  //   setProvinceValue_(e.target.value);
  //   console.log("e.target.value", e.target.value);
  //   //console.log(v.label)
  //   getChartData(e.target.value)
  // }




  return (



    <div>

      {/* <React.Fragment>
        <Button onClick={handleClickOpen('paper')}>scroll=paper</Button>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle style={{ textAlign: "center", color: "", fontFamily: 'Kanit' }} id="scroll-dialog-title">
          พื้นที่เสี่ยงภัยแล้งรายอําเภอ (สัปดาห์ที่ 9)  ช่วงวันที่ 26 กุมภาพันธ์ 2567 - 3 มีนาคม 2567
          </DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {[...new Array(50)]
                .map(
                  () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                )
                .join('\n')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>ปิด</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment> */}








      {/* <Col xs={12} md={12} lg={12} xl={12} xxl={12} >
        <Image style={{ width: '100%' }} src="/Crop_Drought-.svg" fluid />
      </Col> */}

      {/* <Dialog
        style={{ textAlign: "", color: "", fontFamily: 'Kanit' }}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ textAlign: "", color: "", fontFamily: 'Kanit' }} id="alert-dialog-title">
          {"แจ้งข้อมูลในระบบไม่อัพเดท"}
          {"แจ้งปรับปรุงระบบ"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "", color: "", fontFamily: 'Kanit' }} id="alert-dialog-description">
            📢 แจ้งปิดระบบ Web CropsDrought และ Application เช็คแล้ง
            ตั้งแต่วันที่ 12 กค. 2567 เวลา 20.00 - วันที่ 15 กค. 67 เวลา 8.30 เพื่ออัพเดต MAP API เพิ่มประสิทธิภาพของการแสดงผลแผนที่
            ขออภัยในความไม่สะดวก
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText style={{ textAlign: "", color: "", fontFamily: 'Kanit' }} id="alert-dialog-description">
            📢 ประกาศ ขณะนี้บาง web service ของ GISTDA เกิดขัดข้อง ทําให้ข้อมูลที่แสดงอยู่บนระบบทั้งเว็บ และโมบายแอปพลิเคชั่นไม่อัพเดทเป็นข้อมูลปัจจุบัน&nbsp;
            <b>(ท่านสามารถใช้งานระบบและดาวน์โหลดข้อมูลย้อนหลังได้)</b>&nbsp;
            ซึ่งทางทีมที่ดูแลระบบเครือข่ายและสัญญาณของ GISTDA กำลังเร่งดำเนินการแก้ไขอยู่
            ทั้งนี้ หากใช้ได้ตามปกติทาง GISTDA จะแจ้งให้ทราบต่อไป และขออภัยในความไม่สะดวกมา ณ โอกาสนี้
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="warning" onClick={handleClose} autoFocus>
            ปิดหน้าต่าง
          </Button>
        </DialogActions>
      </Dialog> */}



      <Row fixed style={{ backgroundColor: '#FFDE59' }}>
        {/* <Image style={{ width: '100%' }} src="/Crop__Drought.png" /> */}
        <MDBCarousel showControls showIndicators interval={5000}>
          <MDBCarouselItem itemId={1}>
            <img src='/landpage/CropDrought.png' className='d-block w-100' alt='...' />
          </MDBCarouselItem>
          <MDBCarouselItem itemId={2}>
            <img src='/landpage/CropDrought.png' className='d-block w-100' alt='...' />
          </MDBCarouselItem>
          <MDBCarouselItem itemId={3}>
            <img src='/landpage/CropDrought.png' className='d-block w-100' alt='...' />
          </MDBCarouselItem>
        </MDBCarousel>
      </Row>


      {/* <Row xs={12} md={12} lg={12} xl={12} xxl={12}>
        <Image style={{ width: '100%',height:'600px' }} src="/Crop_Drought.png" fluid />
      </Row> */}


      <Row className="justify-content-md-center" style={{ backgroundColor: 'rgb(243 245 255)' }}>
        <Col>
          <Card className="b2">
            <Image style={{ width: '50%', alignSelf: 'center' }} src="/logo-1-1.png" rounded />
            {/* <Card.Img style={{ width: '100%' }} src="/logo-1-1.png" /> */}
            <br />
            <div style={{ textAlign: "", color: "", fontFamily: 'Kanit' }}>การประเมินพื้นที่เสี่ยงภัยแล้งและความเสียหายของพืชเกษตรรายแปลง ด้วยเทคโนโลยีภูมิสารสนเทศ ของสำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) ร่วมกับกรมส่งเสริมการเกษตร กรมชลประทาน กรมอุตุนิยมวิทยา และสำนักปลัดกระทรวงเกษตรและสหกรณ์ ภายใต้ทุนอุดหนุนการวิจัยและนวัตกรรมจาก สำนักงานการวิจัยแห่งชาติ ประจำปี 2564 โดยกำหนดหลักการไว้ว่า จะใช้ศักยภาพของข้อมูลดาวเทียม และเทคโนโลยีภูมิสารสนเทศที่มีอยู่ในการจัดทำฐานข้อมูลและสร้างแบบจำลองการประเมินพื้นที่เสี่ยงภัยแล้งและความเสียหายของพืชเกษตรรายแปลงของประเทศไทยแบบอัตโนมัติ
            </div>
            {/* <Card.Body className="b4">
              <Card.Title>Card title</Card.Title>
              <Card.Text>
              </Card.Text>
            </Card.Body> */}
            <br />
            <br />

            {/* <Image src="/logo/logo-band.png" rounded /> */}
            <Image style={{ width: '70%', alignSelf: 'center' }} src="logo/logo-band.png" rounded />
            <br />
            <div style={{ alignSelf: 'center', color: "", fontFamily: 'Kanit' }}> ดาวน์โหลดแอปพลิเคชัน ของเราได้ที่นี่</div>

            <br />

            <Stack style={{ alignSelf: 'center' }} direction="row" spacing={2}>
              {/* https://play.google.com/store/apps/details?id=com.gistda.cropsdrought */}
              <IconButton color="light" size="large" aria-label="delete" target="_blank" href='https://play.google.com/store/apps/details?id=com.gistda.cropsdrought2' >
                <AndroidIcon fontSize="inherit" />
              </IconButton>
              {/* https://apps.apple.com/th/app/%E0%B9%80%E0%B8%8A-%E0%B8%84%E0%B9%81%E0%B8%A5-%E0%B8%87/id1658491405?l=th */}
              <IconButton color="light" size="large" aria-label="delete" target="_blank" href='https://apps.apple.com/th/app/%E0%B9%80%E0%B8%8A-%E0%B8%84%E0%B9%81%E0%B8%A5-%E0%B8%87/id6476916406?l=th' >
                <AppleIcon fontSize="inherit" />
              </IconButton>
              {/* <Button variant="warning" target='_blank' href={"https://apps.apple.com/th/app/%E0%B9%80%E0%B8%8A-%E0%B8%84%E0%B9%81%E0%B8%A5-%E0%B8%87/id6476916406?l=th"}>ios</Button> */}

              {/* <Button variant="warning" target='_blank' href={"https://play.google.com/store/apps/details?id=com.gistda.cropsdrought"}>Android</Button> */}

            </Stack>

            <Stack style={{ alignSelf: 'center' }} direction="row" spacing={2}>
              {/* https://play.google.com/store/apps/details?id=com.gistda.cropsdrought */}
              {/* <IconButton color="light" size="large" aria-label="delete" target="_blank" href='https://play.google.com/store/apps/details?id=com.gistda.cropsdrought2' >
                <AndroidIcon fontSize="inherit" />
              </IconButton> */}
              <a class="btn btn-info" type="button" href='https://drought-765rkyfg3q-as.a.run.app/api/maunal/maunal_dri-web.pdf' target="_blank">ดาวน์โหลดคู่มือการใช้งานเว็บ แอปพลิเคชั่น</a>

              {/* https://apps.apple.com/th/app/%E0%B9%80%E0%B8%8A-%E0%B8%84%E0%B9%81%E0%B8%A5-%E0%B8%87/id1658491405?l=th */}
              {/* <IconButton color="light" size="large" aria-label="delete" target="_blank" href='https://apps.apple.com/th/app/%E0%B9%80%E0%B8%8A-%E0%B8%84%E0%B9%81%E0%B8%A5-%E0%B8%87/id6476916406?l=th' >
                <AppleIcon fontSize="inherit" />
              </IconButton> */}
              <a class="btn btn-info" type="button" href='https://drought-765rkyfg3q-as.a.run.app/api/maunal/maunal_dri.pdf' target="_blank">ดาวน์โหลดคู่มือการใช้งานโมบาย แอปพลิเคชั่น</a>

              {/* <Button variant="warning" target='_blank' href={"https://apps.apple.com/th/app/%E0%B9%80%E0%B8%8A-%E0%B8%84%E0%B9%81%E0%B8%A5-%E0%B8%87/id6476916406?l=th"}>ios</Button> */}

              {/* <Button variant="warning" target='_blank' href={"https://play.google.com/store/apps/details?id=com.gistda.cropsdrought"}>Android</Button> */}

            </Stack>






          </Card>
        </Col>


        <Col>
          <Card className="b3">
            {/* <iframe
              height="600px"
              playsInline
              loop
              muted
              controls
              alt="All the devices"
              src='https://youtu.be/8-ZkTlWVA-w?si=AIlPSXO_U2iwt_Yd'
              title='YouTube video'
              data-gtm-yt-inspected-2340190_699='true'
              id='388567449'
            ></iframe> */}

            <iframe playsInline loop
              muted
              controls
              height="600"
              src="https://www.youtube.com/embed/fpW5j8toyzc?autoplay=1&mute=1&loop=1&list=PLAzspOJDFP9LGxJVnGgbDaKp49IXnVoDV"
              title="YouTube video player"
              frameborder="0"
              allow='autoplay; encrypted-media'
              allowfullscreen>
            </iframe>


            {/* <Card.Body className="b4">
              <Card.Title>Card title</Card.Title>
              <Card.Text>
              </Card.Text>
            </Card.Body> */}
          </Card>
        </Col>
      </Row>

      {/* <tableau-viz
        id='tableau-viz' src='https://us-west-2b.online.tableau.com/t/gistdatableau/views/Heat_RelativeH_Tem_new_ty/Dashboard_DarkV_Heat/7df9bc23-44df-4d86-83bb-f0e2a6334704/15c360f1-e78e-4288-924c-68f58daf1253'
        width='1536' height='655' hide-tabs='true' toolbar="hidden" hide-edit-button
        token= {isJwt} >
      </tableau-viz> */}

      {/* <tableau-viz
        id='tableauViz' src='https://us-west-2b.online.tableau.com/t/gistdatableau/views/test2/Sheet1'
        width='1536' height='655' hide-tabs toolbar='bottom'
        device="phone">
      </tableau-viz> */}



      <Row className="justify-content-md-center" style={{ backgroundColor: '#d0d5d2' }}>
        <Col>
          <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'center', fontSize: 20 }}>
            พื้นที่เสี่ยงภัยแล้งรายจังหวัด (สัปดาห์ที่ {weekNumber})&nbsp;&nbsp;ช่วงวันที่&nbsp;
            {firstday} - {lastday}
          </Card>
          {/* <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'center', fontSize: 20 }}>
            พื้นที่เสี่ยงภัยแล้งรายจังหวัด (สัปดาห์ที่ 27)&nbsp;&nbsp;ช่วงวันที่&nbsp;
            1 กรกฎาคม 2567 - 7 กรกฎาคม 2567
          </Card>*/}
        </Col>
      </Row >

      <Row className="justify-content-md-center" style={{ backgroundColor: '#d0d5d2' }}>
        <Col>
          <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'center', fontSize: 20 }}>
            <h6>
              "โอกาสได้รับความเสี่ยงสูงมาก"<br /><Badge style={{ color: 'black', backgroundColor: '#cc0000', fontSize: 24 }} bg="">{data_pv_tn_count5_5}</Badge>จังหวัด
            </h6>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'center', fontSize: 20 }}>
            <h6>
              "โอกาสได้รับความเสี่ยงสูง"<br /><Badge style={{ color: 'black', backgroundColor: '#ff6600', fontSize: 24 }} bg="">{data_pv_tn_count4_4}</Badge>จังหวัด
            </h6>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'center', fontSize: 20 }}>
            <h6>
              "โอกาสได้รับความเสี่ยงปานกลาง"<br /><Badge style={{ color: 'black', backgroundColor: '#f9bc42', fontSize: 24 }} bg="">{data_pv_tn_count3_3}</Badge>จังหวัด
            </h6>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'center', fontSize: 20 }}>
            <h6>
              "โอกาสได้รับความเสี่ยงน้อย"<br /><Badge style={{ color: 'black', backgroundColor: '#fef9d3', fontSize: 24 }} bg="">{data_pv_tn_count2_2}</Badge>จังหวัด
            </h6>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'center', fontSize: 20 }}>
            <h6>
              "ไม่ได้รับความเสี่ยง"<br /><Badge style={{ color: 'black', backgroundColor: '#d5ff85', fontSize: 24 }} bg="">{data_pv_tn_count1_1}</Badge>จังหวัด
            </h6>
          </Card>
        </Col>
      </Row >


      {/* <tableau-viz id='tableau-viz' src='https://us-west-2b.online.tableau.com/t/gistdatableau/views/test2/Sheet1' width='1490' height='655' hide-tabs toolbar='bottom' device='phone' ></tableau-viz> */}

      <Row className="justify-content-md-center" style={{ backgroundColor: '#d0d5d2' }}>

        <Col>
          <Card className="b8" style={{ marginTop: 1, backgroundColor: '#ffffff' }}>
            <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="map-container2" ref={mapContainerRef} >
            </Col>
            <iframe style={{ height: '1px' }}></iframe>

            {/* <Box style={{ width: "100%", height: "1100px" }} id="map">
                อาจเอาไว้ใส่พวกเครื่องหมายกลางจอ
                <Fab size='small' sx={{
                  width: '29px',
                  height: '25px',
                  minHeight: 'auto',
                  position: 'absolute',
                  top: 144,
                  left: 10,
                  borderRadius: 1,
                  color: '#21367C',
                  backgroundColor: '#FFF',
                  outline: '2px solid rgba(0, 0, 0, 0.1)',
                  boxShadow: 0,
                }}>
                  <HomeIcon
                    onClick={() => goHome()} />

                </Fab>


                <Tooltip title="คําอธิบายสัญลักษณ์">
                  <Fab size='small' sx={{
                    width: '120px',
                    height: '150px',
                    minHeight: 'auto',
                    position: 'absolute',
                    top: 144,
                    right: 10,
                    bottom: 80,
                    borderRadius: 1,
                    color: '#21367C',
                    backgroundColor: '#FFF',
                    outline: '2px solid rgba(0, 0, 0, 0.1)',
                    boxShadow: 0,
                  }}>
                    <Image style={{ width: '100%' }} src="/legend.png" />



                  </Fab>
                </Tooltip>



              </Box> */}

            {/* <div className="map-container" ref={mapContainerRef}
              /> */}
          </Card>

        </Col>
        <Col>
          <Card className="b7" style={{ marginTop: 1, backgroundColor: '#ffffff' }}>
            <HighchartsReact containerProps={{ style: { height: "100vh", minWidth: 320, maxWidth: 800, margin: 0 } }} highcharts={Highcharts} options={options3} />
          </Card>
        </Col>


      </Row >


      {/* <Row className="justify-content-md-center" style={{ backgroundColor: '#d0d5d2' }}>
        <Col>
          <Card className="b7" style={{ marginTop: 1, backgroundColor: '#ffffff' }}>
            <HighchartsReact containerProps={{ style: { height: "1200px" } }} highcharts={Highcharts} options={options4} />
          </Card>
        </Col>
        <Col>
          <Card className="b7" style={{ marginTop: 1, backgroundColor: '#ffffff' }}>
            <HighchartsReact containerProps={{ style: { height: "1200px" } }} highcharts={Highcharts} options={options4} />
          </Card>
        </Col>
      </Row > */}

      {/* <Row className="justify-content-md-center" style={{ backgroundColor: '#d0d5d2' }}>
        <Card style={{ width: '100%', height: '280px', marginTop: '1px' }}>
          <h6>
            จํานวนจังหวัดที่ได้รับ"โอกาสได้รับความเสี่ยงสูงมาก" <Badge style={{ color: 'black', backgroundColor: '#cc0000', fontSize: 16 }} bg="">{data_pv_tn_count5_5}</Badge>จังหวัด
          </h6>
          <h6>
            จํานวนจังหวัดที่ได้รับ"โอกาสได้รับความเสี่ยงสูง" <Badge style={{ color: 'black', backgroundColor: '#ff6600', fontSize: 16 }} bg="">{data_pv_tn_count4_4}</Badge>จังหวัด
          </h6>
          <h6>
            จํานวนจังหวัดที่ได้รับ"โอกาสได้รับความเสี่ยงปานกลาง" <Badge style={{ color: 'black', backgroundColor: '#f9bc42', fontSize: 16 }} bg="">{data_pv_tn_count3_3}</Badge>จังหวัด
          </h6>
          <h6>
            จํานวนจังหวัดที่ได้รับ"โอกาสได้รับความเสี่ยงน้อย" <Badge style={{ color: 'black', backgroundColor: '#fef9d3', fontSize: 16 }} bg="">{data_pv_tn_count2_2}</Badge>จังหวัด
          </h6>
          <h6>
            จํานวนจังหวัดที่ได้รับ"ไม่ได้รับความเสี่ยง" <Badge style={{ color: 'black', backgroundColor: '#d5ff85', fontSize: 16 }} bg="">{data_pv_tn_count1_1}</Badge>จังหวัด
          </h6>

        </Card>
      </Row > */}

      {/* <Col xs={12} md={12} lg={12} xl={12} xxl={12} >
        <Box style={{ width: "100%", height: 0 }} id="map">
          อาจเอาไว้ใส่พวกเครื่องหมายกลางจอ
          <Fab size='small' sx={{
            width: '29px',
            height: '25px',
            minHeight: 'auto',
            position: 'absolute',
            top: 144,
            left: 10,
            borderRadius: 1,
            color: '#21367C',
            backgroundColor: '#FFF',
            outline: '2px solid rgba(0, 0, 0, 0.1)',
            boxShadow: 0,
          }}>
            <HomeIcon
              onClick={() => goHome()} />

          </Fab>
        </Box>

      </Col> */}



      {/* 
      <Form.Group controlId="formBasicSelect">
        <Form.Label>Select Norm Type</Form.Label>
        <Form.Control
          as="select"
          value={provinceValue_}
          // onChange={e => {
          //   console.log("e.target.value", e.target.value);
          //   setProvinceValue_(e.target.value);
          //   getChartData(e.target.value)
          // }}
          onChange={(e) => onProvinceChange(e)}

        >
          <option value="น่าน">น่าน</option>
          <option value="ตรัง">ตรัง</option>
          <option value="ตราด">ตราด</option>
        </Form.Control>
      </Form.Group> */}

      {/* <header className="jumbotron">
        <h3>{content}</h3>
      </header>

      <div>
        <h1>
          Example heading....{' '}
        </h1>
      </div> */}


      {/* <Col xs={12} md={12} lg={6} xl={6} xxl={6}  >
        <Autocomplete
          disableClearable
          disablePortal id="combo-province"
          options={provinces}
          value={provinceValue.label}
          onChange={(e, v) => onProvinceChange(e, v)}
          sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginBottom: 2 }}
          renderInput={(params) => <TextField  {...params} label="เลือกจังหวัด" />}
        />

      </Col> */}











      {/* <button
        type="button"
        onClick={() => getChartData()}
      >Blue</button> */}


      {/* <div className='text-center text-white' style={{ backgroundColor: '#FFF9C4' }}>
        <Container className='p-4'>
          <section className=''>
            <Row className='d-flex justify-content-center'>
              <Col lg='6'>
                <div className='ratio ratio-16x9'>
                  <iframe
                    className='shadow-1-strong rounded'
                    src='https://cropsdroughtcms.gistda.or.th/wp-content/uploads/2022/06/Clip_SKN_min.mp4'
                    title='YouTube video'
                    allowFullScreen
                    data-gtm-yt-inspected-2340190_699='true'
                    id='388567449'
                  ></iframe>
                </div>
              </Col>
            </Row>
          </section>
        </Container>

        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          © 2023 Copyright:
          <a className='text-white' href='https://cropsdroughtcms.gistda.or.th/wp-content/uploads/2022/06/Clip_SKN_min.mp4'>
            GISTDA
          </a>
        </div>
      </div> */}


      <Row className="justify-content-md-center" style={{ backgroundColor: 'rgb(243 245 255)' }}>
        <Col>
          <Card className="b5">
            <Image style={{ width: '20%', alignSelf: 'center' }} src="/logo/gistda1.png" rounded />
            {/* <div class="d-flex justify-content-around sub-heading">
                <Image style={{ width: '20%', alignSelf: 'center' }} src={`/logo/gistda1.png`} />

                <Image style={{ width: '20%', alignSelf: 'center' }} src={`/logo-1-1.png`} />
            </div> */}

            {/* <Card.Img style={{ width: '100%' }} src="/logo-1-1.png" /> */}
            <br />
            <div style={{ textAlign: "center", fontSize: "20px", color: "", fontFamily: 'Kanit' }}>ติดต่อเรา </div>

            <Row>
              <Col>
                <Card className="b6">
                  <div style={{ display: "flex", justifyContent: "" }}>
                    <LocationOnIcon size={20} /><span> :สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ
                      ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา 5 ธันวาคม 2550
                      เลขที่ 120 อาคารคูนย์ราชการ ตึก B (อาคารรัฐประศาสนภักดี)
                      ชั้น 6-7 ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210</span>
                  </div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "" }}>
                    <PhoneIcon size={20} /><span> :Call Center 0 - 2141 - 4576</span>
                  </div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "" }}>
                    <MarkAsUnreadIcon size={20} /><span> :cropsdrought.g@gistda.or.th</span>
                  </div>
                </Card>
              </Col>
            </Row>


            {/* <Card.Body className="b4">
              <Card.Title>Card title</Card.Title>
              <Card.Text>
              </Card.Text>
            </Card.Body> */}
            <br />
            <br />
            {/* <Image src="/logo/logo-band.png" rounded /> */}

          </Card>
        </Col>


        <Col>
          <Card className="b5">

            <iframe
              class="embed-responsive-item"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.2854165676486!2d100.56211100369806!3d13.881874628875181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2832ff601e93b%3A0x4adcb46a8124f677!2sGeo-Informatics%20and%20Space%20Technology%20Development%20Agency%20(GISTDA)!5e0!3m2!1sen!2sth!4v1692176726623!5m2!1sen!2sth"
              height="500"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>


            {/* <Card.Body className="b4">
              <Card.Title>Card title</Card.Title>
              <Card.Text>
              </Card.Text>
            </Card.Body> */}
          </Card>
        </Col>
      </Row>



      {/* <Row className="justify-content-md-center" style={{ backgroundColor: '#ffffff' }}>
        <hr />
        <Col style={{ textAlign: "center", margin: "30px", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>
          © GISTDA, All Rights Reserved.
        </Col>
        <hr />
        <Col>
          <div style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>   Follow us </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <IconButton size="large" aria-label="delete" target="_blank" href='https://www.facebook.com/gistda/' >
              <FacebookIcon />
            </IconButton>
            <IconButton size="large" aria-label="delete" target="_blank" href='https://www.instagram.com/gistda_space/' >
              <InstagramIcon />
            </IconButton>
            <IconButton size="large" aria-label="delete" target="_blank" href='https://twitter.com/gistda' >
              <TwitterIcon />
            </IconButton>
            <IconButton size="large" aria-label="delete" target="_blank" href='https://www.youtube.com/c/GISTDAspace' >
              <YouTubeIcon />
            </IconButton>
          </div>
        </Col>
      </Row> */}

      <hr />

      <Row className="justify-content-md-center" style={{ backgroundColor: '#f1f2f4' }}>
        <hr />
        <Col style={{ textAlign: "left", margin: "10px", fontSize: "16px", color: "", fontFamily: 'Kanit' }}>
          © GISTDA, All Rights Reserved.
        </Col>
        <Col style={{ textAlign: "center", margin: "10px", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>
          <div style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>   Follow us </div>

          <IconButton size="large" aria-label="delete" target="_blank" href='https://www.facebook.com/gistda/' >
            <FacebookIcon />
          </IconButton>
          <IconButton size="large" aria-label="delete" target="_blank" href='https://www.instagram.com/gistda_space/' >
            <InstagramIcon />
          </IconButton>
          <IconButton size="large" aria-label="delete" target="_blank" href='https://twitter.com/gistda' >
            <TwitterIcon />
          </IconButton>
          <IconButton size="large" aria-label="delete" target="_blank" href='https://www.youtube.com/c/GISTDAspace' >
            <YouTubeIcon />
          </IconButton>
        </Col>
        <Col style={{ textAlign: "right", margin: "10px", fontSize: "16px", color: "", fontFamily: 'Kanit' }}>
          <a href="/term-condition" className="btn btn-outline-primary">
            ข้อตกลงการใช้งาน
          </a> &nbsp;
          <a href="/privacy-policy" className="btn btn-outline-primary">
            นโยบายความเป็นส่วนตัว
          </a>
        </Col>
      </Row>



    </div >

  );



};



export default Home;

